<template>
  <v-container class="d-flex align-center flex-column">
    <v-row>
      <v-col cols="12" style="text-align: center">
        <div>Teste qualquer comando TeX!</div>
        <br>
        <label><textarea rows="8" cols="100" v-model="formula" class="primary-box text--primary"></textarea></label>
        <div style="text-align: center; justify-self: center">
          <button class="tex-action-buttons mr-7" @click="clearText">Consertar acentos</button>
          <button class="tex-action-buttons" @click="removeLineBreaks">Remover quebras de linha</button>
          <button v-if="old_formula" class="tex-action-buttons accent white--text ml-7" @click="goBack">DESFAZER ALTERAÇÃO</button>
        </div>
        <div style="padding-top: 10vh; padding-left: 40px; white-space: pre-wrap; text-align: left;">
          <div :key="formula" class="tex2jax_process">{{formula}}</div>
        </div>
        <br><br>
      </v-col>
    </v-row>
    <v-row class="tex2jax_ignore" style="text-align: left">
      <v-col cols="12">
        <div class="primary-box">
          <p style="padding-left: 20px;"><b>Informações básicas:</b></p>
          <ul>
            <li>Expressões matemáticas entre $$ criam uma nova linha -------- $$e^{\pi i} + 1 = 0$$</li>
            <li>Expressões matemáticas entre $ ficam na mesma linha --------- Pitágoras provou que $a^2+b^2=c^2$ no triângulo retângulo</li>
            <li>No rodapé desta página você encontrará um link com a referência da sintaxe TeX</li>
            <li>Entre em contato conosco sempre que alguma dúvida surgir!</li>
          </ul>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'LiveEditor',
  methods: {
    reRender() { if(window.MathJax) { window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub]); } },
    clearText() {
      if (this.old_formula !== this.formula) { this.old_formula = this.formula }
      // eslint-disable-next-line no-control-regex,vue/no-parsing-error
      this.formula = this.formula.replace(/[􀀀]/gu, '').replace(/[;]/gu, ',').replace(/[:]/gu, '.');
      let finalStringFirst = "";
      for (let charIndex = 0; charIndex < this.formula.length; charIndex++) {
        let pair = this.formula.charAt(charIndex) + this.formula.charAt(charIndex+1);
        if (this.correctedDictFirst[pair]){ finalStringFirst += this.correctedDictFirst[pair]; charIndex++ }
        else { finalStringFirst += this.formula.charAt(charIndex) }
      }
      let finalStringSecond = "";
      for (let charIndex = 0; charIndex < finalStringFirst.length; charIndex++) {
        let pair = finalStringFirst.charAt(charIndex) + finalStringFirst.charAt(charIndex+1);
        if (this.correctedDictSecond[pair]){ finalStringSecond += this.correctedDictSecond[pair]; charIndex++ }
        else { finalStringSecond += finalStringFirst.charAt(charIndex) }
      }
      // for (let fix in correctedDict) { finalString = finalString.replace(fix, correctedDict[fix]) }
      this.formula = finalStringSecond;
    },
    removeLineBreaks() {
      if (this.old_formula !== this.formula) { this.old_formula = this.formula }
      this.formula = this.formula.replace(/[\n]/g, ' ');
    },
    goBack() { this.formula = this.old_formula; this.old_formula = null }
  },
  mounted() { this.reRender(); },
  watch: {
    formula: function() {
      if (!this.awaitingTyping) {
        setTimeout(() => {
          this.$nextTick().then(() => { this.reRender(); this.awaitingTyping = false })
        }, 750)
      } this.awaitingTyping = true;
    },
  },
  data() {
    return {
      old_formula: null,
      formula: 'Exemplo na mesma linha: $x = \\frac{-b \\pm \\sqrt{b^2 - 4ac}}{2a}$\n\nExemplo nova linha: $$\\ce{1 CH4 + 2 O2 -> 1 CO2 + 2 H2O}$$',
      awaitingTyping: false,
      correctedDictFirst: {
        ' ﬁ': 'fi', 'ﬁ ': 'fi',
        ' ¡': ' -', '¡ ': '- ',
        '~a': 'ã', '~A': 'Ã',
        '˜a': 'ã', '˜A': 'Ã',
        '^a': 'â', '^A': 'Â',
        '¶a': 'á', '¶A': 'Á',
        '˜o': 'õ', '˜O': 'Õ',
        '~o': 'õ', '~O': 'Õ',
        'c¸': 'ç', 'C¸': 'Ç',
        '¸c': 'ç', '¸C': 'Ç',
        '´a': 'á', '´A': 'Á',
        '´e': 'é', '´E': 'É',
        '¶e': 'é', '¶E': 'É',
        '´i': 'í', '´I': 'Í',
        '¶i': 'í', '¶I': 'Í',
        '´ı': "í",
        '´o': 'ó', '´O': 'Ó',
        '¶o': 'ó', '¶O': 'Ó',
        '´u': 'ú', '´U': 'Ú',
        '¶u': 'ú', '¶U': 'Ú',
        '`a': 'à', '`A': 'À',
        'ˆa': 'â', 'ˆA': 'Â',
        'ˆe': 'ê', 'ˆE': 'Ê',
        'ˆo': 'ô', 'ˆO': 'Ô',
        '»c': 'ç', '»C': 'Ç',
        '¶³': 'í',
        '^o': 'ô', '^O': 'Ô',
        'a¯': 'afi', 'e¯': 'efi', 'i¯': 'ifi', 'o¯': 'ofi', 'u¯': 'ufi', '¯a': 'fia', '¯b': 'fib', '¯c': 'fic', '¯d': 'fid', '¯e': 'fie', '¯f': 'fif', '¯g': 'fig', '¯h': 'fih', '¯j': 'fij', '¯k': 'fik', '¯l': 'fil', '¯m': 'fim', '¯n': 'fin', '¯o': 'fio', '¯p': 'fip', '¯q': 'fiq', '¯r': 'fir', '¯s': 'fis', '¯t': 'fit', '¯u': 'fiu', '¯v': 'fiv', '¯x': 'fix', '¯z': 'fiz',
        '^e': 'ê', '^E': 'Ê',
      },
      correctedDictSecond: {
        'a~': 'ã', 'A~': 'Ã',
        'a˜': 'ã', 'A˜': 'Ã',
        'a^': 'â', 'A^': 'Â',
        'o˜': 'õ', 'O˜': 'Õ',
        'o~': 'õ', 'O~': 'Õ',
        'a´': 'á', 'A´': 'Á',
        'e´': 'é', 'E´': 'É',
        'e¶': 'é', 'E¶': 'É',
        'i´': 'í', 'I´': 'Í',
        'ı´': "í",
        'o´': 'ó', 'O´': 'Ó',
        'u´': 'ú', 'U´': 'Ú',
        'a`': 'à', 'A`': 'À',
        'aˆ': 'â', 'Aˆ': 'Â',
        'eˆ': 'ê', 'Eˆ': 'Ê',
        'oˆ': 'ô', 'Oˆ': 'Ô',
        'c»': 'ç', 'C»': 'Ç',
        'o^': 'ô', 'O^': 'Ô',
        'e^': 'ê', 'E^': 'Ê',
      },
    }
  },
}
</script>

<style lang="scss">
  .primary-box { border: 2px solid var(--v-primary-lighten2); border-radius: 5px; padding: 6px !important; }
  .tex-action-buttons { padding: 3px 5px; border-radius: 10px; background-color: var(--v-primary-lighten2); width: 200px; height: 2.2rem; }
</style>
